<template>
	<div>
		<Header :navbg='true'></Header>
		<div class="content">
			<div class="title">
				<div class="nav-list">
					<div class="nav-item" :class="[navIndex == showItemIndex ? 'show-item' : '']" v-for="(navItem,navIndex) in navList"
					 @click="navItemClick(navIndex)" :key="navIndex">
						<div class="title">
							{{navItem.name}}
							<van-icon name="arrow-down" class="icon" v-if="navItem.children.length" />
						</div>
						<div class="nav-item-list">
							<div class="nav-itemitem" :class="[navItemItemIndex == activeIndex ? 'active' : '']" v-for="(navItemItem,navItemItemIndex) in navItem.children"
							 @click="navItemItemClick(navItemItem,navItemItemIndex)" :key="navItemItemIndex">
								{{navItemItem.name}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="news-list" v-if="total>0">
				<div class="news-item" v-for="(item,index) in newsList" :key="index" @click="articleTap(item)">
					<div class="image-wrap">
						<van-image :src="item.image" fit="cover" class="image" />
					</div>
					<div class="bottom-wrap">
						<div class="title-wrap">
							<div class="title van-ellipsis">{{item.title}}</div>
							<van-icon name="arrow" class="icon" />
						</div>
						<div class="time-wrap">
							<div class="time">
								<div class="iconfont icon-shijian"></div>
								<div>{{item.createtime}}</div>
							</div>
							<div class="browse-wrap">
								<div class="iconfont icon-liulan"></div>
								<div>{{item.views}}</div>
							</div>
						</div>
					</div>
				</div>

				<van-pagination class="pagination" :total-items="total" v-model="current_page" :items-per-page="per_page"
				 force-ellipses @change="change">
					<template #prev-text>
						<van-icon name="arrow-left" />
					</template>
					<template #next-text>
						<van-icon name="arrow" />
					</template>
				</van-pagination>
			</div>
			<div class="not-news-list" v-else>
				暂无信息
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from '@/components/head2022.vue'
	import Footer from '@/components/foot2022.vue'
	import utils from '@/common/utils'
	import {
		mapState
	} from 'vuex'
	export default {
		name: "newsList",
		data() {
			return {
				showItemIndex: -1,
				activeIndex: 0,
				navList: [{
					name: "新闻中心",
					children: []
				}],
				newsList: [],
				total: 0,
				per_page: 10,
				current_page: 1,
				channel: null, //所有分类集合
			}
		},
		components: {
			Header,
			Footer,
		},
		computed: {
			...mapState(['newsApi', 'ocApi']),
			paramsName: function() {
				if (this.$route.name == "newsList" && this.$route.params.paramsName) {
					let paramsName = this.$route.params.paramsName
					this.channel = this.$route.params.paramsName
					return paramsName;
				} else {
					return ''
				}
			},
			columnName: function() {
				let paramsName = this.paramsName;
				for (let item of this.navList[0].children) {
					if (paramsName == item.params) {
						return item.name
					}
				}
			}
		},
		watch: {
			paramsName: {
				handler: function(e) {
					this.newsList = []
					this.current_page = 1
					this.total = 0
					this.getArticleList();
				}
			}
		},
		created: function() {
			this.getNewsClass();
		},
		methods: {
			change(e) {
				this.current_page = e;
				this.newsList = [];
				this.total = 0
				this.getArticleList();
			},
			articleTap: utils.debounce(function(e) {
				this.$router.push({
					name: 'newsDetail',
					params: {
						id: e.id
					},
					query: {
						columnName: this.columnName
					}
				});
			}, 500, true),
			getNewsClass() {
				let that = this;
				that.$axios.get(that.ocApi + 'appmanage/dictionary', {
						params: {
							code: 'web_news'
						}
					})
					.then(res => {
						let data = res.data.data;
						let dataArr = [];
						for (const item of data) {
							dataArr.push(item.value);
						}
						that.channel = dataArr.join(',');

						let newArr = data.map(iterator => {
							return {
								name: iterator.name,
								pathName: 'newsList',
								params: iterator.value,
							}
						});
						let origin = [{
							name: '全部',
							pathName: 'newsList',
							params: dataArr.join(','),
						}]
						that.navList[0].children = origin.concat(newArr)
						that.getArticleList('');
					})
			},
			getArticleList: function(flag) {
				let that = this;
				that.$axios.post(that.newsApi + 'index/index', {
						channel: that.channel,
						page: that.current_page,
						flag
					})
					.then(res => {
						if (res.data.code == 1) {
							that.total = res.data.data.total;
							that.newsList = res.data.data.data;
						} else {
							that.$toast.fail(res.data.msg);
						}
					})
			},
			navItemClick: function(e) {
				if (this.showItemIndex == e) {
					this.showItemIndex = -1;
				} else {
					this.showItemIndex = e;
				}
			},
			// 二级菜单切换
			navItemItemClick: function(e, i) {
				if (e.pathName) {
					this.$router.push({
						name: e.pathName,
						params: {
							paramsName: e.params
						}
					});
				}
				this.activeIndex = i;
			},
		},
	}
</script>

<style lang="scss" scoped>
	.van-pagination {
		 justify-content: center;
		:deep(.van-pagination__next){
			flex: none;
			padding: 0;
		}
		:deep(.van-pagination__item) {
		   color: #666666;
		   height: 40px;
		   width: 40px;
		   min-width: 40px;
		}
		:deep(.van-pagination__prev) {
			flex: none;
			padding: 0;
		}

		:deep(.van-pagination__item--active) {
			background-color: #666666;
			color: #FFFFFF;
		}

	}

	.content {
		width: 100%;
		padding-bottom: 20px;

		>.title {
			width: 100%;
			padding: 50px 0px 0px;
			box-sizing: border-box;

			.nav-list {
				display: block;

				.nav-item {
					border-top: 1px solid #E6E6E6;
					min-height: 50px;
					line-height: 50px;
					.title {
						display: flex;
						padding-left:20px;
						font-size: 16px;
						color: #303233;

						.icon {
							line-height: 50px;
							transition: all 0.4s;
							font-size: 20px;
							color: #c0c5c9;
							margin-left: 5px;							
						}
					}

					&.show-item {
						.title {
							.icon {
								transform: rotate(180deg);
								-ms-transform: rotate(180deg);
								-webkit-transform: rotate(180deg);
							}
						}

						.nav-item-list {
							max-height: 300px;
							transition: all 0.4s;
							display: block;
						}
					}

					.nav-item-list {
						max-height: 0;
						overflow: hidden;
						transition: all 0.4s;
						padding: 0 20px;
						display: none;

						.nav-itemitem {
							display: flex;
							justify-content: space-between;
							align-items: center;
							font-size: 14px;
							border-bottom: 1px solid #E6E6E6;
							color: #303233;

							&.active {
								color: #666666;
							}

							&:last-child {
								border: none;
							}
						}
					}

				}
			}
		}

		.news-list {
			width: 100%;
			padding: 0 18px;
			box-sizing: border-box;

			.news-item {
				width: 100%;
				padding: 14px 0 12px;
				border-bottom: 1px solid #e6e6e6;

				&:first-child {
					padding: 0px 0 12px;
				}

				.image-wrap {
					width: 100%;

					.image {
						width: 100%;
					}
				}

				.bottom-wrap {
					.title-wrap {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-top: 13px;

						.title {
							flex: 1;
							font-size: 14px;
							color: #303233;
						}

						.icon {
							margin-left: 5px;
						}
					}

					.time-wrap {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-top: 12px;
						color: #999999;
						font-size: 12px;

						.time {
							display: flex;
							align-items: center;

							.iconfont {
								margin-right: 5px;
								font-size: 12px;
							}
						}

						.browse-wrap {
							display: flex;
							align-items: center;

							.iconfont {
								margin-right: 5px;
								font-size: 12px;
							}
						}
					}
				}
			}

			.pagination {
				margin-top: 56px;
			}
		}

		.not-news-list {
			width: 100%;
			height: 200px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
</style>
